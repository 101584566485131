class AudioAnalyser {

    constructor(stream, callbackSetValue) {
        this._stream = stream;
        this._callbackSetValue = callbackSetValue;

        this._analyser = null;
        this._frequencyArray = null;
        this._active = false;
    }

    init() {
        const AudioContext = window.AudioContext || window.webkitAudioContext;
        const audioContent = new AudioContext();

        const audioStream = audioContent.createMediaStreamSource( this._stream );
        this._analyser = audioContent.createAnalyser();
        const fftSize = 1024;

        this._analyser.fftSize = fftSize;
        audioStream.connect(this._analyser);

        const bufferLength = this._analyser.frequencyBinCount;
        this._frequencyArray = new Uint8Array(bufferLength);
    }

    start() {
        this._active = true;
        this._step()
    }

    stop() {
        this._active = false
    }

    _step() {
        const level = this._calculateAudioLevel();
        this._callbackSetValue(level);
        if (this._active) window.requestAnimationFrame(() => { this._step() });
    }

    _calculateAudioLevel() {
        this._analyser.getByteFrequencyData(this._frequencyArray);
        let adjustedLengths = [];
        for (var i = 0 ; i < 255; i++) {
            adjustedLengths[i] = Math.floor(this._frequencyArray[i]) - (Math.floor(this._frequencyArray[i]) % 5);
        }
        let total = 0;
        let audioLevel = 0;
        for (var a = 0 ; a < adjustedLengths.length; a++) {
            total += adjustedLengths[a];
        }
        audioLevel = total / adjustedLengths.length;
        return audioLevel;
    }

}

export default AudioAnalyser